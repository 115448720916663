import NavBarre from "../component/NavBarre";
import React from "react";
import Footer from "../component/Footer";


function Cgu(){
    return(
        <div className="containerPage ">
            <NavBarre/>
            <div className="container-mention">
                <h1 className="h1mention text-green-600">Conditions Générales d'Utilisation (CGU)</h1>

                <div className="para para1">

                    <h2 className="title-mention">**1. Acceptation des conditions d'utilisation**</h2><br/>

                    <p>En accédant et en utilisant ce site web, vous acceptez sans réserve les présentes Conditions
                        Générales d'Utilisation. Si vous n'acceptez pas ces<br/>
                        conditions, veuillez ne pas utiliser ce site.</p>
                </div>

                <div className="para para2">

                    <h2 className="title-mention">**2. Utilisation du site**</h2><br/>

                    <p>Ce site est destiné à fournir des informations sur ALC Paysages et ses activités. Vous vous
                        engagez à utiliser ce site uniquement à des fins légales <br/>
                        et conformément à ces conditions d'utilisation.</p>
                </div>
                <div className="para para3">

                    <h2 className="title-mention">**3. Propriété intellectuelle**</h2><br/>

                    <p>Tous les contenus présents sur ce site, y compris les textes, les images, les logos et les
                        marques, sont la propriété exclusive de ALC Paysages ou <br/>
                        de ses partenaires et sont protégés par les lois sur la propriété intellectuelle. Toute
                        reproduction ou utilisation non autorisée de ces contenus est <br/>
                        strictement interdite.</p>
                </div>

                <div className="para para4">

                    <h2 className="title-mention">**4. Responsabilité**</h2><br/>

                    <p>ALC Paysages s'efforce de fournir des informations précises et à jour sur ce site, mais ne peut
                        garantir l'exactitude, l'exhaustivité ou la pertinence<br/>
                        de ces informations. En aucun cas, ALC Paysages ne pourra être tenu responsable des dommages
                        directs ou indirects résultant de l'utilisation<br/>
                        de ce site.</p>
                </div>

                <div className="para para5">

                    <h2 className="title-mention">**5. Liens vers des sites tiers**</h2><br/>

                    <p>Ce site peut contenir des liens vers des sites web tiers. Ces liens sont fournis uniquement pour
                        votre commodité et ALC Paysages n'assume <br/>
                        aucune responsabilité quant au contenu de ces sites.</p>
                </div>

                <div className="para para6">

                    <h2 className="title-mention">**6. Modification des conditions d'utilisation**</h2><br/>

                    <p>ALC Paysages se réserve le droit de modifier ces Conditions Générales d'Utilisation à tout
                        moment. Les modifications prendront effet dès leur<br/>
                        publication sur ce site. Il est de votre responsabilité de consulter régulièrement ces
                        conditions pour prendre connaissance des éventuelles<br/>
                        modifications.


                    </p>
                </div>

                <div className="para para7">

                    <h2 className="title-mention">**7. Droit applicable et juridiction compétente**</h2><br/>

                    <p>Les présentes conditions d'utilisation sont régies par le droit français. En cas de litige, les
                        tribunaux français seront seuls compétents.

                    </p>
                </div>
                <div className="para para8">


                    <p>En utilisant ce site, vous acceptez de vous conformer à ces Conditions Générales d'Utilisation. Si vous avez des questions ou des préoccupations <br/>
                        concernant ces conditions, veuillez nous contacter à <strong>alc.paysages@gmail.com.</strong>
                    </p>
                </div>

            </div>
            <Footer/>
        </div>
    )
}

export default Cgu;
