import React, { useState, useEffect } from 'react';
import arrowTop from "../assets/icons/arrowTop.png";

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Fonction pour faire défiler vers le haut
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Fonction pour vérifier la position de défilement et afficher le bouton
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Ajoute un écouteur d'événements pour le défilement
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (

        <div className="scroll-to-top" data-aos="fade-up" >
            {isVisible &&
                <div className="block-arrow"  onClick={scrollToTop} >
                    <img src={arrowTop} className="arrow" alt="Scroll to top" />
                </div>
            }
        </div>
    );
};

export default ScrollToTop;
