import React from 'react';
import logo from "../assets/icons/logo_white.png"
import zones_inter from "../assets/img/zones-intervention-white.png"

function Footer() {

    return (
        <footer className="blocFooter">

            <div className="flex px-4 sm:px-8 md:px-16 lg:px-32 h-32 w-full items-center justify-between">
                <img 
                    src={logo} 
                    className="object-cover h-20 sm:h-24 md:h-28 lg:h-32" 
                    alt="Logo de l'entreprise"
                />
                <img 
                    src={zones_inter} 
                    className="object-cover h-20 sm:h-24 md:h-28 lg:h-32" 
                    alt="Zone d'inter"
                />
            </div>
            <div className="blocCopyRight">
                <p className="textRightFooter text-xs">Copyright. Tous droits réservés.</p>
                <p className="textRightFooter text-xs">© 2024 ALC PAYSAGES</p>
                <div className="mentionCgu">
                    <a className="linkCgu text-xs underline" href={"/mentionLegal"}>Mention Légal </a>
                    <p className=" text-xs" style={{width:"1vw", color:"#6f6f6f"}}>-</p>
                    <a className="linkCgu text-xs underline" href={"/cgu"}> CGU</a>
                </div>
            </div>
            <div className="blocDirectionFooter">
                <a className="linkFooter text-sm" href="/">Accueil</a>
                <a className="linkFooter text-sm" href="/services">Services</a>
                <a className="linkFooter text-sm" href="/realisations">Réalisations</a>
                <a className="linkFooter text-sm" href="/professionnel">Professionel</a>
                <a className="linkFooter text-sm" href="/contact">Obtenir un devis</a>
            </div>
        </footer>
    );
}

export default Footer;
