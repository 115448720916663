import React, { useEffect, useState } from 'react';
import icoCross from "../assets/icons/cross.svg";

function Comparaison() {
    const [imageBefore, setImageBefore] = useState([]);


    useEffect(() => {
        fetch('/api/beforeAfter', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to get image');
                }
            })
            .then(result => {
                const images = result.reduce((acc, item) => {
                    if (item.realisation === null) {
                        acc.push({
                            avant: item.avant,
                            apres: item.apres,
                            title: item.title,
                            content: item.content
                        });
                    }
                    return acc;
                }, []);
                setImageBefore(images);
            })
            .catch(error => {
                console.error('Error:', error);
                alert('An error occurred while fetching images');
            });
    }, []);

    const tabReverse = [...imageBefore].reverse();

    const deleteComparaison = (image) => {

        const result = window.confirm("Souhaitez-vous supprimer l'image ?");


        if(result === false){
            return;
        }
        const token = localStorage.getItem('token');

        const body = JSON.stringify({
            avant: image.avant,
            apres: image.apres,
            title: image.title,
        });

        fetch('/api/deleteComparaison', {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: body
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to delete image');
                }
            })
            .then(result => {
                alert('Comparaison deleted successfully');
                setImageBefore(imageBefore.filter(img => img.title !== image.title));
            })
            .catch(error => {
                console.error('Error:', error);
                alert('An error occurred while deleting the comparaison');
            });
    };

    useEffect(() => {
        const elements = document.getElementsByClassName("deleteComparaison");
        if (elements.length > 0) {
            if (!localStorage.getItem("token")) {
                for (let i = 0; i < elements.length; i++) {
                    elements[i].style.display = "none";
                }
            }
        }
    }, [tabReverse]);


    return (
        <div className="gallery-container">
            {tabReverse.map((item, index) => (
                <div key={index} className="blocCardAvantAp" data-aos="fade-up" data-aos-once="true">
                    <div className="blocImgAvant">
                        <div className="blocImageAvAp">
                            <img src={item.avant} className="imgAvantAp" alt="réalisation avant intervention de l'entreprise"/>
                            <p className="absoluteImg text-xl">Avant</p>
                        </div>
                        <div className="blocImageAvAp">
                            <img src={item.apres} className="imgAvantAp" alt="réalisation après intervention de l'entreprise"/>
                            <p className="absoluteImg text-xl">Après</p>
                        </div>
                    </div>

                    <div className="blocContentAvAp">
                        <h3 className="titleAvAP text-3xl">{item.title}</h3>
                        <p className="content">{item.content}</p>
                        <div className="ligneCard"></div>
                        <p>By alc-paysage</p>
                        <a href="/contact">
                            <button className="contact-button text-xs">
                                Nous contacter
                            </button>
                        </a>
                    </div>

                    <button onClick={() => deleteComparaison(item)} className="deleteComparaison">
                        <img src={icoCross} className="croixPopup" alt="croix pour supprimer le bloc"/>
                    </button>
                </div>
            ))}
        </div>
    );
}

export default Comparaison;
