import './App.css';
import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./views/LandingPage";
import ServicePage from "./views/ServicePage";
import RealisationPage from "./views/RealisationPage";
import ProfessionnelPage from "./views/ProfessionnelPage";
import AdminPannel from "./views/adminPannel";
import Login from "./views/auth/login";
import ProtectedRoute from "./protectedRoutes";
import MentionLegal from './views/mentionLegal';
import Cgu from "./views/Cgu";
import Page404 from "./views/404";
import Contact from "./views/contactPage";
import LoadingScreen from "./component/loadingScreen";


  import AOS from 'aos'; // Si vous utilisez AOS
  import 'aos/dist/aos.css'; // Import du style

function App() {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const handleLoad = () => {
  //     setLoading(false);
  //   };

  //   if (document.readyState === 'complete') {
  //     handleLoad();
  //   } else {
  //     window.addEventListener('DOMContentLoaded', handleLoad);
  //     window.addEventListener('load', handleLoad);
  //   }

  //   return () => {
  //     window.removeEventListener('DOMContentLoaded', handleLoad);
  //     window.removeEventListener('load', handleLoad);
  //   };
  // }, []);

  
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const images = Array.from(document.querySelectorAll('img'));
    let loadedImages = 0;

    const handleImageLoad = () => {
      loadedImages += 1;
      if (loadedImages === images.length) {
        console.log('ALL IMAGE LOADED');
        setTimeout(() => {
          console.log('STOP LOADING', images.length);
          setLoading(false);
        }, 200)
      }
    };

    if (images.length === 0) {
      setLoading(false);
      return;
    }

    images.forEach((img) => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener('load', handleImageLoad);
        img.addEventListener('error', handleImageLoad);
      }
    });

    return () => {
      images.forEach((img) => {
        img.removeEventListener('load', handleImageLoad);
        img.removeEventListener('error', handleImageLoad);
      });
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      AOS.init();
      AOS.refresh();
    }
  }, [loading]);

  const router = createBrowserRouter([
    { path: '/', element: <LandingPage /> },
    { path: '/services', element: <ServicePage /> },
    { path: '/realisations', element: <RealisationPage /> },
    { path: '/professionnel', element: <ProfessionnelPage /> },
    { path: '/adminPannel', element: <ProtectedRoute element={AdminPannel} /> },
    { path: '/login', element: <Login /> },
    { path: '/mentionLegal', element: <MentionLegal /> },
    { path: '/cgu', element: <Cgu /> },
    { path: '/contact', element: <Contact /> },
    { path: '/404', element: <Page404 /> },
    { path: '*', element: <Page404 /> }
  ]);

  return (
    <>
      {/* Toujours monter la vraie page */}
      <div style={{ display: loading ? "none" : "block" }}>
        <RouterProvider router={router} />
      </div>

      {/* Afficher l'écran de chargement si nécessaire */}
      {loading && <LoadingScreen />}
    </>
  );
}

export default App;
