import React from 'react';

const BurgerButton = ({ toggleMenu, isOpen }) => {
    const changeDisplay = () => {
        const menu = document.getElementsByClassName("menu")[0];
        if (menu) {
            menu.style.display = menu.style.display === "none" ? "none" : "block";
        }
    };

    const handleClick = () => {
        changeDisplay();
        toggleMenu();
    };


    return (
        <div onClick={handleClick} className={`burger-button ${isOpen ? 'open' : ''}`}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </div>
    );
};

export default BurgerButton;
