import React, { useState, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import NavBarre from "../component/NavBarre";
import Footer from "../component/Footer";
import Gallery from "../component/GaleryReal";
import Comparaison from "../component/comparaison";
import ScrollTop from "../component/ScrollTop";
import CursorClick from '../assets/icons/cursor_click.svg'

function RealisationPage() {
    const [title, setTitle] = useState("Réalisations");
    const [secondTitle, setSecondTitle] = useState("Avant / Après");
    const nodeRef = useRef(null);

    function changeTitle() {
        if (title === "Réalisations") {
            setTitle("Avant / Après");
            setSecondTitle("Réalisations");
        } else {
            setTitle("Réalisations");
            setSecondTitle("Avant / Après");
        }
    }

    return (
        <div className="containerPage">
            <NavBarre />
            <div className="pageContainerRealisation">
                <div className="blocRealisation">
                    <SwitchTransition>
                        <CSSTransition
                            nodeRef={nodeRef}
                            key={title}
                            classNames="fade"
                            timeout={200}
                        >
                            <h1 ref={nodeRef} data-aos="fade-right" data-aos-once="true" className="text-4xl titleRealQueri textGreen">{title}</h1>
                        </CSSTransition>
                    </SwitchTransition>
                    <button onClick={changeTitle} data-aos="fade-right" data-aos-once="true" className="text-sm transitionButton">
                        <img src={CursorClick} class="w-5" alt="Icone SVG" />
                        {secondTitle}
                    </button>

                    {title === "Réalisations" ? <Gallery /> : <Comparaison />}

                </div>
            </div>
            <ScrollTop />
            <Footer />
        </div>
    );
}

export default RealisationPage;
