import NavBarre from "../component/NavBarre";
import FormContact from "../component/FormContact";
import React from "react";
import Footer from "../component/Footer";


function Contact(){


    return(
        <div className={'containerPage'}>
            <NavBarre/>
            <div className="pageContainer">
                <FormContact />
            </div>
            <Footer/>
        </div>
    )

}

export default Contact;
