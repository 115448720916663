import React from 'react';
import loadImg from "../assets/icons/logo.png"

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <img className="imgLoading" src={loadImg} alt='logo'/>
            <div className="spinner"></div>
            <p>Chargement de la page, veuillez patienter...</p>
        </div>
    );
};

export default LoadingScreen;
